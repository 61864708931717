<template>
  <b-modal
    id="asup-group-item-config-modal"
    v-model="isShow"
    title="Configuration"
    no-close-on-backdrop
    scrollable
    @ok="save"
  >
    <b-overlay :show="isLoading || isSaving">
      <b-form
        v-if="groupAsset"
        @submit.stop.prevent
      >
        <b-form-group label="Show if status is at least">
          <b-form-select
            v-model="groupAsset.options.showIfStatusIsAtLeast"
            :options="showStatusAtLeastOptions"
          />
        </b-form-group>

        <b-form-group
          v-if="assetSupportsClientMatrix(groupAsset.asset)"
        >
          <b-form-checkbox
            v-model="groupAsset.options.includeClientMatrix"
          >
            Include Client Matrix
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="assetSupportsClientMatrix(groupAsset.asset)"
        >
          <b-form-checkbox
            v-model="groupAsset.options.showMatrixRowsWithClientErrorsOnly"
          >
            Hide succeeded clients
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="assetSupportsClientMatrix(groupAsset.asset)"
        >
          <b-form-checkbox
            v-model="groupAsset.options.hideMatrixGroups"
          >
            Hide groups in matrix
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="assetSupportsClientMatrix(groupAsset.asset)"
        >
          <b-form-checkbox
            v-model="groupAsset.options.hideSystemSummary"
          >
            Hide system summary
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="assetSupportsClientMatrix(groupAsset.asset)"
          label="Matrix day range"
        >
          <b-form-select
            v-if="assetSupportsClientMatrix(groupAsset.asset)"
            v-model="groupAsset.options.matrixDayRange"
            :options="matrixDayRangeOptions"
          />
        </b-form-group>

        <b-form-group
          v-if="assetSupportsClientMatrix(groupAsset.asset)"
          label="Group Filter"
          description="Regular Expression to show only specific group in report."
        >
          <b-form-input
            v-model="groupAsset.options.groupFilterRegex"
            :state="groupFilterRegexValidation"
          />
          <b-form-invalid-feedback :state="groupFilterRegexValidation">
            Regular expression is not valid.
          </b-form-invalid-feedback>
        </b-form-group>
        <span>
          What are groups? <br>
          <ul>
            <li>NetWorker: Policy->Workflow</li>
            <li>Avamar: Policy</li>
            <li>PPDM: Protection Policy</li>
            <li>Veeam: Job</li>
          </ul>
        </span>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>

import {
  BModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BOverlay, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import AsupGroupReportingService from '@/service/asupGroupReporting.service'
import { productFamilySupportsClientMatrix } from '@/utils/helpers'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BOverlay,
    BFormCheckbox,
    BFormSelect,
  },
  data() {
    return {
      isShow: false,
      isLoading: false,
      isSaving: false,
      asupGroupId: null,
      groupAsset: null,
    }
  },
  computed: {
    showStatusAtLeastOptions() {
      return [
        { value: 0, text: 'Ok' },
        { value: 10, text: 'Info' },
        { value: 20, text: 'Warning' },
        { value: 30, text: 'Error' },
      ]
    },
    matrixDayRangeOptions() {
      return [
        { value: 5, text: '5 days' },
        { value: 7, text: '7 days' },
        { value: 16, text: '16 days' },
        { value: 21, text: '21 days' },
        { value: 28, text: '28 days' },
        { value: 31, text: '31 days' },
        { value: 45, text: '45 days' },
      ]
    },
    groupFilterRegexValidation() {
      if (!this.groupAsset) {
        return false
      }
      if (this.groupAsset.options.groupFilterRegex) {
        try {
          RegExp(this.groupAsset.options.groupFilterRegex)
        } catch (e) {
          // string is not a valid regular expression
          return false
        }
      }

      return true
    },
  },
  methods: {
    show(asupGroupId, groupAssetId) {
      this.isShow = true
      this.isSaving = false
      this.isLoading = true

      this.asupGroupId = asupGroupId

      AsupGroupReportingService.getAsync(asupGroupId, groupAssetId)
        .then(result => {
          this.groupAsset = result.data.assets.find(x => x.id === groupAssetId)

          if (this.groupAsset === null) {
            this.$swal({
              title: 'Could not find asset in report group.',
              icon: 'error',
            })
              .then(() => {
                this.isShow = false
              })
          }
        }, err => {
          this.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
          this.isShow = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    assetSupportsClientMatrix(asset) {
      if (!asset) {
        return false
      }

      return productFamilySupportsClientMatrix(asset.productFamily, asset.model)
    },
    save(event) {
      event.preventDefault()

      this.isSaving = true
      AsupGroupReportingService.updateAssetAsync(this.asupGroupId, this.groupAsset.asset.id, {
        options: this.groupAsset.options,
      })
        .then(
          () => {
            this.$toast('Updated')

            this.$emit('updated', {
              asupGroupId: this.asupGroupId,
              assetId: this.groupAsset.asset.id,
            })
            this.isShow = false
          },
          err => {
            this.$swal({
              title: 'Something went wrong!',
              text: err.response.data.error.message,
              icon: 'error',
            })
          },
        )
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>
